import { getAction, postAction } from '../utils/request'

export function exercisesList(data) {
  return getAction('/web/exercises/findExercisesPage', data)
}

export function addExercises(data) {
  return postAction('/web/exercises/addExercises', data)
}

export function updateExercises(data) {
  return postAction('/web/exercises/updateExercises', data)
}

export function deleteExercises(data) {
  return getAction('/web/exercises/deleteExercises', data)
}
